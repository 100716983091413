// @flow
import React from 'react'

import DefaultLayout from '../components/DefaultLayout'
import DownloadBadges from '../components/DownloadBadges'
import BlogLinkStrip from '../blocks/BlogLinkStrip'
import IntroStrip from '../blocks/IntroStrip'
import {Col, Row} from '../ui/layout'

const Download = () => (
  <DefaultLayout title="Download Superdays" description="Superdays works on Android and iOS">
    <IntroStrip bottomWave="curve" headline="Download Superdays">
      <Col spacing={3} align="center" paddingBottom={3}>
        <Row>Superdays works on Android and iOS.</Row>
        <DownloadBadges />
      </Col>
    </IntroStrip>
    <BlogLinkStrip headline="What do you get again?" />
  </DefaultLayout>
)

export default Download
